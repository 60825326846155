// jshint esversion: 6, strict:false, undef:false, unused:false

/**
	Bootstrap Tabs and Modal Linking v2.03
	Stephen Ginn at Crema Design Studio

	Usage: example.com/?modal=success OR example.com/?tab=pricing

	IMPORTANT NOTES:
	- This function is compatible with BOTH Bootstrap and Bootstrap Native.
	- Using an IIFE function here will break the Bootstrap fade animation.
/*/

// @codekit-prepend "functions/getUrlParameter.js";

function tabModalLinks() {
	var modalSlug = getUrlParameter('modal'), tabSlug = getUrlParameter('tab');

	if (typeof $ !== 'undefined') {
		// Regular Bootstrap
		if ($().tab && tabSlug !== undefined) {
			$('a[href="#tab-' +tabSlug+ '"]').tab('show');
		}

		if ($().modal && modalSlug !== undefined) {
			$('#modal-' + modalSlug).modal('show');
		}
	} else if (typeof BSN !== 'undefined') {
		// Bootstrap Native
		if (Modal && modalSlug !== undefined) {
			new Modal(document.querySelector('#modal-' + modalSlug)).show();
		}
	}
}

window.addEventListener("DOMContentLoaded", tabModalLinks);
