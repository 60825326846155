/*/
	Form Serialization
	Stephen Ginn at Crema Design Studio
	Last Updated on 2021-07-01
/*/

// Serialize Forms as either a URL String or JSON object:
const serialize = form => new URLSearchParams(new FormData(form)).toString();
const serializeJSON = form => Object.fromEntries(new FormData(form));

// Retrieves input data from a form and returns it as a JSON object:
// This might have an issue with checkboxes though?
const formToJSON = form => Object.fromEntries(new FormData(form));

// Same as function above, but doesn't have issues with checkboxes
function getValues(form) {
	var inputs = form.querySelectorAll('[name]'),
		fields = {};

	for (i = 0; i < inputs.length; i++) {
		var input = inputs[i],
			name = input.getAttribute("name"),
			field = fields[name],
			value = input.value;

		switch(input.type) {
			case "checkbox":
				if (input.checked) {
					if (fields[name] === undefined) fields[name] = [];
					fields[name].push(value);
				}
			break;
			case "radio":
				if (input.checked) fields[name] = value;
			break;
			default:
				fields[name] = value;
		}
	}

	return fields;
}

// Same as function above, but doesn't work in IE11
function getValues1(form) {
	var object = {};

	new FormData(form).forEach((value, key) => {
		if (Object.prototype.hasOwnProperty.call(object, key)) {
			var current = object[key];
			current = (Array.isArray(current) ? current : object[key] = [current]);
			current.push(value);
		} else {
			object[key] = value;
		}
	});

	return object;
}

// Based on https://stackoverflow.com/a/62010324
var getValues2 = form => {
	const fd = new FormData(form);

	return Object.fromEntries(Array.from(fd.keys()).map(key => [
		key, fd.getAll(key).length > 1 ? fd.getAll(key) : fd.get(key)
	]))
}

const getQueries = form => {
	return Object.values(getValues(form)).flat().filter(d => d);
}

// Get Shopify Friendly URL String
const getUrlString = data => Object.entries(data).map(e => e.join('=')).join('&');
