// jshint strict:false, undef:false, unused:false

/*/
	EXTEND OBJECT
	Performs a shallow extend on a JSON object.
	Useful for extending settings in plugins.

	Usage:
	function MyAwesomePlugin(options) {
		var settings = extend({
			debug: false,
			selector: '[data-fetch]',
			recipient: '[name=recipient]',
			alert: '[data-alert="status"]',
			inputs: '[name]',
			btn: '.btn-submit'
		}, options);

		console.log("Your New Settings" + settings);
	}
/*/

function extend(a, b) {
    for(var key in b) {
        if(b.hasOwnProperty(key)) {a[key] = b[key];}
	}
    return a;
}
