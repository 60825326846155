// jshint strict:false, undef:false, unused:false

/*/
	Cremastrap BS4 Plugins
	Stephen Ginn at Crema Design Studio
	Last Updated on 2020-12-09
/*/

// Cremastrap Helpers
window.S = (s, scope = document) => scope.querySelectorAll(s);

// @codekit-append "crema/js/functions/getUrlParameter.js";
// @codekit-append "crema/js/functions/extend.js";
// @codekit-append "crema/js/functions/serialize.js";

// Cremastrap Plugins
// @codekit-append "crema/js/tabmodal-links.js"
// @codekit-append "crema/js/form-fetcher.js";
// @codekit-append "captcha.min.js";

// Third Party Plugins
// @codekit-append "imask/build/imask.js";
